<template>
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'" v-loading="loadingData">
            <div class="deal-task-tracker" >
            <div class="card-table-body ifon-add-title-block">
                <el-row>
                    <el-col :span="12">
                        <div class="content-in__title filter-title">
                            <h1 class="content-title mr-3" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t('message.waiting_product')}}</h1>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <div class="filter_filial">
                                        <select-from-filial
                                            :size="'medium'"
                                            :select_only="false"
                                            :class="mode ? 'filter__day' : 'filter__night'"
                                            :placeholder="columns.from_filial.title"
                                            v-model="filterForm.from_filial_id"
                                        >
                                        </select-from-filial>

                                        <select-to-filial
                                            :size="'medium'"
                                            :show_all="1"
                                            :class="mode ? 'filter__day' : 'filter__night'"
                                            :placeholder="columns.to_filial.title"
                                            v-model="filterForm.to_filial_id"
                                        >
                                        </select-to-filial>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="card-table-header table-crm-smart custom__scroll">
                <div class="card-table-header table-crm-smart custom__scroll">
                    <el-tabs v-model="activeTabName" @tab-click="handleTabClick" type="card">
                        <el-tab-pane name="packages">
                            <span slot="label" :class="activeTabName !== 'packages' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.packages') }} </span>
                            <div v-if="activatedTabs.includes('packages')">
                                <packages-table></packages-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="products">
                            <span slot="label" :class="activeTabName !== 'products' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.products') }} </span>
                            <div v-if="activatedTabs.includes('products')">
                                <products-table></products-table>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane name="parcels">
                            <span slot="label" :class="activeTabName !== 'parcels' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.parcels') }} </span>
                            <div v-if="activatedTabs.includes('parcels')">
                                <parcels-table></parcels-table>
                            </div>
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import productsTable from "./components/products-table";
import packagesTable from "./components/packages-table";
import parcelsTable from "./components/parcels-table";

export default {
    name: "tasks",
    mixins: [list, form, drawer],
    components: {
        selectFromFilial,
        selectToFilial,
        productsTable,
        packagesTable,
        parcelsTable
    },
    
    data() {
        return {
            created: false,
            showHistory: false,
            activeTabName: 'packages',
            activatedTabs: ['packages'],
        };
    },
    async created() {
        this.created = true;
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
        this.$set(this.filterForm, 'circulated', true);
        this.debouncedFetchData = await _.debounce(this.fetchData, 500);
    },
    computed: {
        ...mapGetters({
            list: "productCirculations/list",
            permissions: "auth/permissions",
            columns: "productCirculations/columns",
            pagination: "productCirculations/pagination",            
            filter: "productCirculations/filter",
            sort: "productCirculations/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "productCirculations/waitingIndex",
            updateParcelList: "dealProducts/waitingParcels",
            updatePackageList: "packageForProductCirculations/waitingIndex",
            setPagination: "productCirculations/setPagination",
            updateSort: "productCirculations/updateSort",
            updateFilter: "productCirculations/updateFilter",
            updateColumn: "productCirculations/updateColumn",
            updatePagination: "productCirculations/updatePagination",
            show: "productCirculations/show",
            empty: "productCirculations/empty",
            delete: "productCirculations/destroy",
            refreshData: "productCirculations/refreshData",
        }),

        changeFilials() {
            if(this.filterForm.from_filial_id && this.filterForm.to_filial_id){
                let from = JSON.parse(JSON.stringify(this.filterForm.from_filial_id));
                let to = JSON.parse(JSON.stringify(this.filterForm.to_filial_id));
                this.$set(this.filterForm, 'from_filial_id', to);
                this.$set(this.filterForm, 'to_filial_id', from);
            }
        },
        handleTabClick(tab, event){
            if(this.activatedTabs.includes(tab.name)){
                this.debouncedFetchData();
            }
            if(!this.activatedTabs.includes(tab.name)){
                this.activatedTabs.push(tab.name);
            }
        },
        async fetchData() {
            let query = {...this.filter, ...this.pagination, ...this.sort};
            if (!this.loadingData && !this.created) {
                if(this.activeTabName === 'packages'){
                    this.loadingData = true;
                    await this.updatePackageList(query)
                        .then(res => { this.loadingData = false })
                        .catch(err => { this.loadingData = false });
                }
                if(this.activeTabName === 'products'){
                    this.loadingData = true;
                    await this.updateList(query)
                        .then(res => { this.loadingData = false })
                        .catch(err => { this.loadingData = false });
                }
                if(this.activeTabName === 'parcels'){
                    this.loadingData = true;
                    query = {...query, cargo_type: 'parcel'};
                    await this.updateParcelList(query)
                        .then(res => { this.loadingData = false })
                        .catch(err => { this.loadingData = false });
                }
            }
            this.created = false;
        },
        
        showIncomingHistory(){
            this.showHistory = !this.showHistory;
            this.$set(this.filterForm, 'showHistory', this.showHistory)
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('productCirculations/EMPTY_LIST');
        next()
    },
};
</script>

<style>
.coloa3 {
   background: #ff3f3f;
}
.container__tasks {
    width: 100% ;
    margin: 0 !important;
}
.test__width {
    width: 100% !important;
}
.filter_filial{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 500px;
    min-height: 20px;
}
</style>
